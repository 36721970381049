import React from 'react';
import { Box } from 'rebass';

import TagItem from './TagItem';

const TagList = ({ tags }) => {
  if (!tags || tags.length === 0) return null;

  return (
    <Box
      css={`
        display: inline-block;

        @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
          display: block;
          margin-left: -10px;
        }
      `}
    >
      {tags.map((tag, index) => (
        <TagItem key={tag} name={tag} isFirst={index === 0} />
      ))}
    </Box>
  );
};

export default TagList;

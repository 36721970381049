import './global.css';
import './prismjs.css';

import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`  
  a {
    color: ${({ theme }) => theme.colors.textLink};
    text-decoration: none;
  }
`;

export default GlobalStyles;

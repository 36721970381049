import React from 'react';
import { graphql } from 'gatsby';
import ArticleItem from '../components/ArticleItem';

import Bio from '../components/Bio';
import Layout from '../components/Layout';
import SEO from '../components/seo';

class BlogIndex extends React.Component {
  render() {
    const { data, location } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout location={location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`breakingjs`, `gatsby`, `javascript`, `react`]}
        />
        <Bio />

        {posts.map(({ node }) => (
          <ArticleItem post={node} key={node.fields.slug} />
        ))}
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
            tags
            summary
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`;

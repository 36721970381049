import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { Box } from 'rebass';
import { Link } from 'gatsby';

import theme from '../style/theme';
import GlobalStyles from '../style/GlobalStyles';
import PageTitle from './PageTitle';
import PageHeader from './PageHeader';

class Layout extends React.Component {
  render() {
    const { title, children, location } = this.props;
    const isHome = location.pathname === '/';

    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <PageHeader />
          <Box
            mx={['2.5%', '10%', '20%', '20%', '30%']}
            bg="#fdfdfd"
            p={['15px', '15px', '20px', '30px']}
            mt={['-285px', '-275px']}
            mb={[3, 4]}
            css={`
              min-height: 90vh;
              position: relative;
              border-radius: 5px;
              box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.2);
            `}
          >
            <GlobalStyles />
            <PageTitle>
              {isHome ? title : <Link to="/">{title}</Link>}
            </PageTitle>
            {children}
          </Box>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default Layout;

import React from 'react';
import { Heading } from 'rebass';

const PageTitle = props => (
  <Heading
    {...props}
    as="h1"
    mt={[2]}
    mb={4}
    fontSize={[6]}
    css={`
      color: ${({ theme }) => theme.colors.textNeutral};
    `}
  />
);

export default PageTitle;

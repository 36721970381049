import React, { Fragment } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';

import profilePic from '../../content/assets/profile-pic.jpg';

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata;
        return (
          <Flex
            px="15px"
            py={['10px', '20px']}
            bg="rgba(255, 229, 100, 0.2)"
            mb={[4]}
            justifyContent="center"
            alignItems={['center', 'flex-start']}
            css={`
              border-radius: 5px;
            `}
          >
            <Box as={Link} to="/about-me" mr={[3]}>
              <Image src={profilePic} alt={author} />
            </Box>

            <DescriptionContainer aboveMobile>
              <LongDescription author={author} social={social} />
            </DescriptionContainer>

            <DescriptionContainer mobileOnly>
              <ShortDescription author={author} social={social} />
            </DescriptionContainer>
          </Flex>
        );
      }}
    />
  );
}

const DescriptionContainer = ({ ...props }) => {
  const maxOrMin = ({ mobileOnly }) => (mobileOnly ? 'min' : 'max');
  return (
    <Box
      as="p"
      m={0}
      fontSize={16}
      {...props}
      css={`
      line-height: 1.4;
      @media (${maxOrMin}-width: ${({ theme }) => theme.breakpoints[0]}) {
        display: none;
      }
    `}
    />
  );
};

const LongDescription = ({ author, social }) => (
  <Fragment>
    Hi, I'm <strong>{author}</strong> (
    <Link to="/about-me">see more about me</Link>) and you just land on my
    personal blog !
    <Br />
    You will find articles on things I digged in for myself or relatives that
    you might find interesting.
    <Br />
    If you like what I do, you can{' '}
    <a href={`https://twitter.com/${social.twitter}`} target="_blank">
      follow me on Twitter!
    </a>
  </Fragment>
);

const ShortDescription = ({ author, social }) => (
  <Fragment>
    Hi, I'm <strong>{author}</strong> (<Link to="/about-me">see more</Link>) and
    if you like what I do you can{' '}
    <a href={`https://twitter.com/${social.twitter}`} target="_blank">
      follow me on Twitter!
    </a>
  </Fragment>
);

const Br = styled.br`
  line-height: 2em;
`;

const Image = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    width: 50px;
    height: 50px;
  }
`;

const bioQuery = graphql`
  query BioQuery {
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`;

export default Bio;

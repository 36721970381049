import React from 'react';
import { Box } from 'rebass';

const TagItem = ({ children, name, isFirst }) => {
  const { bg, label } = mapTagNameToProps(name);

  return (
    <Box
      px={'10px'}
      py={'2px'}
      ml={isFirst ? '10px' : '5px'}
      bg={bg}
      css={`
        display: inline-block;
        border-radius: 4px;
        font-size: 12px;
      `}
    >
      {label}
    </Box>
  );
};

const mapTagNameToProps = name => {
  switch (name) {
    case 'advanced': {
      return {
        bg: '#ff98008c',
        label: 'Advanced topic',
      };
    }
    case 'beginner': {
      return {
        bg: '#ff93c09e',
        label: 'Beginner friendly',
      };
    }
    case 'react': {
      return {
        bg: '#bee2ff',
        label: 'React',
      };
    }
    case 'gatsby': {
      return {
        bg: '#d6b1ff',
        label: 'Gatsby',
      };
    }
    default: {
      return {
        bg: '#e8e8e8',
        label: name,
      };
    }
  }
};

export default TagItem;

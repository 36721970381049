import { Link } from 'gatsby';
import React from 'react';
import { Heading, Box, Text } from 'rebass';
import TagList from './TagList';

const ArticleItem = ({ post }) => {
  const title = post.frontmatter.title || post.fields.slug;
  const linkTo = post.fields.slug;
  const date = post.frontmatter.date;
  const tags = post.frontmatter.tags;
  const summary = post.frontmatter.summary;
  const excerpt = post.excerpt;
  const readingTime = post.fields.readingTime.text;

  return (
    <Box mb={4}>
      <Heading
        as="h3"
        mb={2}
        fontSize={[4, 5]}
        css={`
          color: ${({ theme }) => theme.colors.textTitle};
        `}
      >
        <Link to={linkTo}>{title}</Link>
      </Heading>
      <small>
        {date} - {readingTime}
      </small>
      <TagList tags={tags} />
      <Text
        as="p"
        mt={2}
        dangerouslySetInnerHTML={{ __html: summary || excerpt }}
      />
    </Box>
  );
};

export default ArticleItem;

import React from 'react';
import styled from 'styled-components';

export default function PageHeader() {
  return (
    <Container>
      <GradientBG />
      <SVG
        height="100%"
        width="100%"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <path
          d="M 0 100 L 100 100 L 100 80 C 50 125 50 35 0 80"
          fill="#f9f9f9"
          shapeRendering="optimizeQuality"
        />
      </SVG>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 300px;
  position: relative;
`;

const SVG = styled.svg`
  position: absolute;
`;

const GradientBG = styled.div`
  background-image: linear-gradient(to right bottom, #fc2f1a, #f3045d, #ff9a05);
  width: 100%;
  height: 100%;
  position: absolute;
`;
